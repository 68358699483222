/** @jsx jsx */
import { jsx } from "theme-ui";
import { Fragment } from "react";

const Layout = ({ children }) => {
  return (
    <Fragment>
      {/* <Header /> */}
      <main
        sx={{
          mx: "0 auto",
          display: "grid",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        {children}
      </main>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default Layout;
